import { Spinny } from 'components/logo-animations'
import { Transition } from '@headlessui/react'
import { getLayoutProps } from 'components/layout'

export default function NotFound() {
    return (
        <div className="flex-1 flex flex-col items-center justify-center h-full w-full">
            <div className="text-center flex flex-col gap-12 items-center">
                <Transition
                    show={true}
                    appear={true}
                    as={Spinny}
                    enter="transition-transform duration-500 delay-200 ease-in-out"
                    enterFrom="translate-y-0 rotate-0"
                    enterTo="-rotate-[30deg] translate-y-16"
                />
                <p className="text-xl font-semibold">Sorry, the page you requested could not be found.</p>
            </div>
        </div>
    );
}

export async function getStaticProps(ctx) {
    return {
        props: {
            layoutProps: await getLayoutProps(ctx),
        },
        revalidate: Number(process.env.REVALIDATE_INTERVAL || 3600),
    }
}
