import React from 'react'

export const LogoBg = () => (
    <svg width="67px" height="80px" viewBox="0 0 67 80" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Website-V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Logo-break-down" transform="translate(-202.000000, -47.000000)" fill="#DD6ABA">
                <path d="M258.568542,56.7056275 C271.379971,69.5170558 271.508085,90.2088851 258.952885,103.177921 L258.568542,103.568542 L235.137085,127 L211.705627,103.568542 L211.321285,103.177921 C198.766085,90.2088851 198.894199,69.5170558 211.705627,56.7056275 C224.646464,43.7647908 245.627706,43.7647908 258.568542,56.7056275 Z M235,53 C220.088312,53 208,65.0883118 208,80 C208,94.9116882 220.088312,107 235,107 C249.911688,107 262,94.9116882 262,80 C262,65.0883118 249.911688,53 235,53 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </svg>
);
export const LogoPropeller = React.forwardRef(function LogoPropeller({ className }, ref) {
    return (
    <svg ref={ref} className={className} width="89px" height="55px" viewBox="0 0 89 55" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Website-V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Logo-break-down" transform="translate(-288.000000, -57.000000)">
                <g id="Group-2" transform="translate(288.274170, 57.628737)">
                    <g id="Group" fill="#1E368E">
                        <path d="M21.834937,6.61939679 C29.1965333,10.7308799 36.6020367,15.9672129 41.417064,20.4474002 C42.2612648,20.078063 43.1785887,19.8862124 44.1060521,19.8862124 C45.2394837,19.8862124 46.3879822,20.1727508 47.4401092,20.7751582 C49.4721061,21.9391271 50.6546291,24.0020568 50.7719329,26.1651124 C57.0850659,28.0639798 65.3449388,31.8032413 72.6037939,36.0658038 C83.9635146,42.736483 86.9217152,46.949591 87.6742214,48.8674826 C88.1436664,50.0637972 88.1054804,51.1209076 87.5614818,52.0093493 C87.0577907,52.920641 86.154056,53.4816672 84.8751289,53.6767936 C84.588128,53.7203889 84.2396053,53.7495527 83.8213779,53.7495527 C81.2547351,53.7495527 76.0556846,52.6536575 66.1649115,47.1296846 C58.5485604,42.8759198 50.8852108,37.4183983 46.0923152,32.8396532 C45.4491663,33.0403927 44.7740846,33.1436874 44.0935437,33.1436874 C42.9601121,33.1436874 41.8116136,32.857149 40.7594866,32.2547417 C38.9820757,31.2366047 37.8546143,29.5306548 37.5214921,27.6715196 C31.1708507,25.8009369 22.7674463,22.0122247 15.3960547,17.6835782 C4.03633392,11.012899 1.07813329,6.79918974 0.325627173,4.88159873 C-0.143514778,3.68528412 -0.105631868,2.6284744 0.438669775,1.74003266 C0.942057875,0.828440329 1.8454895,0.267414175 3.12441652,0.072588372 C5.17494264,-0.238892387 10.3321704,0.19525647 21.834937,6.61939679 Z" id="Combined-Shape"></path>
                    </g>
                    <path d="M41.7845649,25.1900218 C42.1413418,24.5766724 42.717708,24.1378713 43.4072094,23.954927 C43.6374887,23.8935037 43.8713312,23.8634549 44.1033921,23.8634549 C44.5661776,23.8634549 45.0231727,23.9836502 45.435181,24.2196218 C46.0534162,24.5735792 46.4957126,25.1453905 46.6805596,25.8294429 C46.8649611,26.5134954 46.7700879,27.2280386 46.413311,27.841388 C45.6761502,29.1074153 44.0392524,29.5426813 42.7626948,28.811788 C41.4865827,28.0808947 41.0478495,26.4560491 41.7845649,25.1900218" id="Path" fill="#D4FCB1"></path>
                </g>
            </g>
        </g>
    </svg>
)});

export const Spinny = React.forwardRef(function Spinny({ className, outerClassName = "w-[89px]" }, ref) {
    return (
        <div className={`relative ${outerClassName}`}>
            <div className="w-full flex justify-center">
                <LogoBg />
            </div>
            <div className="absolute top-[5px] left-0 right-0 bottom-0">
                <LogoPropeller ref={ref} className={className} />
            </div>
        </div>
    )
});
